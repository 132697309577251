import store from "../store/store";
import axios from "@/axios";
import { handleProductDetailRedirect } from "../utils/navigationsGuard";

export default [
  {
    path: "/basic",
    redirect: "/basic/notice-board",
    meta: {
      rule: "editor",
    },
    component: () => import("@/layouts/main/basic/BasicMain.vue"),
    children: [
      {
        path: "notice-board",
        name: "notice-board",
        component: () => import("@/views/basic/Dashboard.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Dashboard",
          rule: "editor",
        },
      },
      {
          path: "events",
          name: "events",
          component: () => import("@/views/basic/Events.vue"),
          meta: {
          pageTitle: "Fresh Clinics | Events",
          rule: "editor",
          },
      },
      {
        path: "supplier-training",
        name: "supplier-training",
        component: () => import("@/views/basic/SupplierTraining.vue"),
        meta: {
        pageTitle: "Fresh Clinics | Supplier Training",
        rule: "editor",
        },
      },
      {
        path: "referral",
        name: "referral",
        component: () => import("@/views/pages/referral/referralPage.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/home" },
          //   { title: "Refer Someone to Fresh", active: true },
          // ],
          pageTitle: "Fresh Clinics | Refer a Friend",
          rule: "editor",
        },
      },
      {
        path: "resources",
        name: "resources",
        component: () => import("@/views/nurse/Resources.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      {
        path: "resources/search",
        name: "basic-search-resources",
        component: () => import("@/views/nurse/ResourceSearch.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      {
        path: "resources/:topic",
        name: "basic-topic-resources",
        component: () => import("@/views/nurse/ResourceTopic.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      {
        path: "resources/:topic/:brand",
        name: "basic-partner-resources",
        component: () => import("@/views/nurse/ResourcePartner.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Resources",
          rule: "editor",
        },
      },
      // Clinic
      {
        path: "clinics",
        name: "basicClinics",
        component: () => import("@/views/org-owner/clinics/list.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Clinics",
          rule: "editor",
        },
      },
      {
        path: "clinic/:clinicId/edit",
        name: "BasicEditClinic",
        component: () => import("@/views/org-owner/clinics/editClinic.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/dashboard" },
          //   { title: "Invoices", active: true },
          // ],
          pageTitle: "Fresh Clinics | Org Owner | Clinics | Edit",
          rule: "editor",
        },
      },
      // Staff
      {
        path: "staff",
        name: "staff",
        component: () => import("@/views/organization/users/index.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Staff", active: true },
          ],
          pageTitle: "Fresh Clinics | Staff",
          rule: "editor",
        },
      },
      // /** AFTERCARE ROUTE STARTED */
      {
        path: "aftercare",
        name: "BasicAftercare",
        component: () => import("@/views/aftercare/AftercareDocuments.vue"),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/basic/aftercare"}
          ],
          pageTitle: "Fresh Clinics | Aftercare",
          rule: "editor",
          permission: "CustomAftercareDocument",
        },
      },
      {
        path: "aftercare/create",
        name: "BasicAftercareCreate",
        component: () => import("@/views/doctor/aftercare/CreateAftercare.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Create Aftercare",
          rule: "editor",
          permission: "CustomAftercareDocument",
        },
      },
      {
        path: "aftercare/:id/details",
        name: "BasicAftercareDetails",
        component: () => import("@/views/doctor/aftercare/CreateAftercare.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Aftercare Details",
          rule: "editor",
          permission: "CustomAftercareDocument",
        },
      },
      /** AFTERCARE ROUTE ENDED */
      // Invoices
      {
        path: "invoices",
        name: "invoices",
        component: () => import("@/views/organization/invoices/List.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Invoices", active: true },
          ],
          pageTitle: "Fresh Clinics | Invoices",
          rule: "editor",
        },
      },
      // Compliance
      {
        path: "compliance",
        name: "BasicCompliance",
        component: () =>
          import("@/views/org-owner/compliance/Compliance.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/review",
        name: "BasicComplianceReview",
        component: () => import("@/views/org-owner/compliance/Review.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Compliance", url: "/org-owner/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/step/image/upload",
        name: "BasicComplianceStepImageUpload",
        component: () =>
          import("@/views/org-owner/compliance/complianceUpload.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Compliance", url: "/org-owner/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/upload",
        name: "BasicComplianceStepUpload",
        component: () =>
          import("@/views/org-owner/compliance/complianceVideoUpload.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Compliance", url: "/org-owner/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/step/booking",
        name: "BasicComplianceStepBooking",
        component: () =>
          import("@/views/org-owner/compliance/schedulebooking.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Compliance", url: "/org-owner/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance | Booking",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/reschedule/booking",
        name: "basicComplianceRescheduleBooking",
        component: () =>
          import("@/views/org-owner/compliance/rescheduleBooking.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Compliance", url: "/org-owner/compliance" },
            { title: "Compliance Review", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance | Reschedule Booking",
          rule: "editor",
        },
      },
      {
        path: "compliance/:clinicId/stocktake/:complianceId",
        name: "basicCompliancedrugbookStocktake",
        component: () =>
          import("@/views/org-owner/compliance/complianceCheck.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/clinics" },
            { title: "Compliance", url: "/org-owner/compliance" },
            { title: "Compliance Check", active: true },
          ],
          pageTitle: "Fresh Clinics | Compliance Check",
          rule: "editor",
        },
      },
      // Drugbook
      {
        path: "drugbook/setup",
        name: "BasicDrugbook",
        component: () =>
          import("@/views/org-owner/drug-book/SetUpDrugbook.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook",
          rule: "editor",
        },
      },
      {
        path: "drugbook/setup/clinic",
        name: "BasicDrugbookTransfer",
        component: () =>
          import("@/views/org-owner/drug-book/TransferClinicDrugbook.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook | Set up",
          rule: "editor",
        },
      },
      {
        path: "drugbook",
        name: "BasicDrugbookLanding",
        component: () =>
          import("@/views/org-owner/drug-book/Drugbook.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook",
          rule: "editor",
          permission: "Drug"
        },
      },
      {
        path: "drugbook/entries",
        name: "BasicDrugbookEntries",
        component: () =>
          import("@/views/org-owner/drug-book/drugbookEntries.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Drugbook | Outstanding",
          rule: "editor",
        },
      },
      //Inventory
      {
        path: "inventory",
        name: "BasicInventory",
        component: () => import("@/views/org-owner/inventory/Inventory.vue"),
        meta: {
          breadcrumb: [
            {title: "Home", url: "/org-owner/notice-board"},
            {title: "Inventory", active: true }
          ],
          pageTitle: "Fresh Clinics | Insight - Inventory",
          rule: "editor",
          permission: "Drug"
        },
      },
      // Shop
      {
        path: "shop",
        name: "BasicOrderCenter",
        component: () =>
          import("@/views/org-owner/ecommerce/OrderCenter.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/basic/shop" },
            { title: "Shop", active: true },
          ],
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      {
        path: "shop/category/:slug",
        name: "BasicOrderCenterCategoryPage",
        component: () =>
          import("@/views/org-owner/ecommerce/Category.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/shop" },
          //   { title: "Shop", active: true },
          // ],
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      {
        path: "shop/brands/:slug",
        name: "BasicOrderCenterBrandPage",
        component: () =>
          import("@/views/org-owner/ecommerce/Brand.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/shop" },
          //   { title: "Shop", active: true },
          // ],
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      {
        path: "shop/promotions",
        name: "BasicOrderCenterPromotionsPage",
        component: () =>
          import("@/views/org-owner/ecommerce/ShopPromotions.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      {
        path: "shop/fresh-life",
        name: "BasicOrderCenterFreshLifePage",
        component: () =>
          import("@/views/org-owner/ecommerce/FreshlifePromotion.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Shop | Fresh Life Promos",
          rule: "editor",
        },
      },
      {
        path: "shop/search",
        name: "BasicOrderCenterSearch",
        component: () =>
          import("@/views/org-owner/ecommerce/SearchResult.vue"),
        meta: {
          // breadcrumb: [
          //   { title: "Home", url: "/org-owner/shop" },
          //   { title: "Shop", active: true },
          // ],
          pageTitle: "Fresh Clinics | Shop",
          rule: "editor",
        },
      },
      {
        path: "product/:id",
        name: "BasicProductDetail",
        component: () =>
          import("@/views/org-owner/ecommerce/ProductDetail.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Product Detail",
          rule: "editor",
        },
        beforeEnter: handleProductDetailRedirect,
      },
      {
        path: "checkout",
        name: "BasicProductCheckout",
        component: () =>
          import("@/views/org-owner/ecommerce/ClinicCheckout.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/basic/shop" },
            { title: "Shop", url: "/basic/shop" },
            { title: "Product Checkout", active: true },
          ],
          pageTitle: "Fresh Clinics | Product Checkout",
          rule: "editor",
        },
      },
      {
        path: "order-complete",
        name: "BasicOrderComplete",
        component: () =>
          import("@/views/org-owner/ecommerce/ClinicOrderComplete.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/shop" },
            { title: "Shop", url: "/org-owner/shop" },
            { title: "Order Complete", active: true },
          ],
          pageTitle: "Fresh Clinics | Order Complete",
          rule: "editor",
        },
      },
      {
        path: "orders",
        name: "BasicOrderList",
        component: () =>
          import("@/views/org-owner/ecommerce/order/OrderList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/shop" },
            { title: "Orders", active: true },
          ],
          pageTitle: "Fresh Clinics | Orders",
          rule: "editor",
        },
      },
      {
        path: "orders/:orderId",
        name: "BasicOrderDetail",
        component: () =>
          import("@/views/org-owner/ecommerce/order/OrderDetail.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/shop" },
            { title: "Orders", url: "/org-owner/orders" },
            { title: "Order Detail", active: true },
          ],
          pageTitle: "Fresh Clinics | Order Detail",
          rule: "editor",
        },
      },
      // Settings
      {
        path: "settings/treatment-areas",
        name: "BasicTreatmentAreas",
        component: () =>
          import("@/views/franchise/settings/TreatmentArea.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Treatment Areas",
          rule: "editor",
        },
      },
      {
        path: "settings/products-list",
        name: "BasicProductsList",
        component: () =>
          import("@/views/franchise/settings/ProductsList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/org-owner/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Treatment Areas",
          rule: "editor",
        },
      },
      /** Custom Product ROUTE START */
      {
        path: "settings/custom-products-list",
        name: "BasicCustomProductsList",
        component: () =>
          import("@/views/franchise/settings/CustomProductsList.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/basic/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Custom Products",
          rule: "editor",
          permission: "CustomProduct",
        },
      },
      {
        path: "settings/custom-products-list/create",
        name: "BasicCustomProductsListCreate",
        component: () =>
          import("@/views/components/org-owner/custom-products/AddCustomProduct.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/basic/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Custom Products | Create",
          rule: "editor",
          permission: "CustomProduct",
        },
      },
      {
        path: "settings/custom-products-list/:productId/edit",
        name: "BasicCustomProductsListDetails",
        component: () =>
          import("@/views/components/org-owner/custom-products/EditCustomProduct.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/basic/dashboard" },
            { title: "List", active: true },
          ],
          pageTitle: "Fresh Clinics | Settings | Custom Products | Edit",
          rule: "editor",
          permission: "CustomProduct",
        },
      },
      /** Custom Product ROUTE END */
      {
        path: "profile",
        name: "BasicUserProfileDetails",
        component: () => import("@/views/components/profile/ProfileDetail.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "nurse/dashboard" },
            { title: "Profile", active: true },
          ],
          pageTitle: "Fresh Clinics | Profile",
          rule: "editor",
        },
      },
      /**INSIGHTS ROUTE STARTED */
      {
        path: "insights",
        name: "BasicClinicDashboard",
        component: () => import("@/views/superAdmin/clinic-dashboard-v2/ClinicDashboard.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Insights",
          rule: "editor",
        },
        beforeEnter: async (to, from, next) => {
          try {
            const userId = store.state.AppActiveUser.id;
            const organizationId = localStorage.getItem(
              `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
            );
            const token = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`);
            const authHeader = {
              Authorization: token
            }

            const { data: orgData } = await axios.get(`/api/v1/organization/${organizationId}`, {
              headers: authHeader
            })
            const { data: permissionData } = await axios.get(`/api/v1/admins/user-permission?userId=${userId}&organizationId=${organizationId}`, {
              headers: authHeader
            })

            const hasPermission = permissionData
              .filter((item) => item.permission.name === "Insights");

            if (hasPermission.length && orgData.data.isAnalyticsEnabled) {
              next();
            } else {
              next({ path: "/notice-board" });
            }
          } catch (error) {
            console.log(`Error fetching: ${error}`)
            next({ path: from.path });
          }
        },
      },
      /**Basic POS Route Started**/
      {
        path: "pos",
        name: "BasicPOS",
        component: () =>
          import("@/views/nurse/pos/nursePos.vue"),
        meta: {
          pageTitle: "Fresh Clinics | POS",
          rule: "editor",
        },
      },
      /**Basic POS Route Ended**/
      /** CONSENT FORM ROUTE STARTED */
      {
        path: "consent-forms",
        name: "BasicConsentForms",
        component: () => import("@/views/general-component/consent-forms/ConsentForms.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
          permission: "CustomConsentForm",
        },
      },
      {
        path: "consent-forms/:consentId/details",
        name: "BasicConsentFormsDetails",
        component: () => import("@/views/general-component/consent-forms/ConsentFormsName.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
          permission: "CustomConsentForm",
        },
      },
      {
        path: "consent-forms/create",
        name: "BasicConsentFormsCreate",
        component: () => import("@/views/general-component/consent-forms/ConsentFormsName.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Consent Forms",
          rule: "editor",
          permission: "CustomConsentForm",
        },
      },
      /** CONSENT FORM ROUTE ENDED */
    ],
  }
]
