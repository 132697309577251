import Vue from "vue";
import App from "./App.vue";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
import VueMapbox from "vue-mapbox";
import Mapbox from "mapbox-gl";

Vue.use(VueMapbox, { mapboxgl: Mapbox });
Vue.use(Vuesax);
    
// Marker API
import "./plugins/marker";

// Global event bus
export const EventBus = new Vue();

// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;

// API Calls
import "./http/requests";

// mock
import "./fake-db/index.js";

// Theme Configurations
import "../themeConfig.js";

// Firebase
import "@/firebase/firebaseConfig";

// Region Helper
import regionHelper from "./globalHelpers/regionHelper";
Vue.use(regionHelper);

// Store Helper
import storeHelper from "./globalHelpers/storeHelper";
Vue.use(storeHelper);

// Auth0 Plugin
import AuthPlugin from "./plugins/auth";
Vue.use(AuthPlugin);

// ACL
import acl from "./acl/acl";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// i18n
import i18n from "./i18n/i18n";

// Vuexy Admin Filters
import "./filters/filters";

// Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// Tour
import VueTour from "vue-tour";
Vue.use(VueTour);
require("vue-tour/dist/vue-tour.css");

// VeeValidate
import VeeValidate from "vee-validate";
VeeValidate.setMode("eager");

VeeValidate.Validator.localize("en", {
  custom: {
    password: {
      regex:
        "Password must contain at least 8 characters including one uppercase letter, one lowercase letter and one number."
    }
  }
});

Vue.use(VeeValidate, {
  classes: true
});

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'

Vue.use(ElementUI, { locale });

// Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: "YOUR_API_KEY",
    libraries: "places" // This is required if you use the Auto complete plug-in
  }
});

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./assets/css/iconfont.css");

// Inter font
require("./assets/css/interfont.css");

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;
/** Date Processing */
import moment from "moment";
Vue.filter("moment", function(date) {
  return moment(date).format("Do MMMM YYYY");
});

/** Currency formatter */
import Vue2Filters from "vue2-filters";
Vue.use(Vue2Filters);

Vue.mixin({
  methods: {
    open_in_new_tab: function(url) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank");
      link.click();
    },
    check_has_permission: function(task) {
      const userRole = store.state.AppActiveUser.userType;

      if (
        userRole.toLowerCase() === "nurse" ||
        userRole.toLowerCase() === "doctor"
      ) {
        return false;
      }

      if (userRole === "admin") {
        const permissions = store.state.AppActiveUser.permissions;
        console.log("This is the permission :::", permissions[task]);

        if (
          task === "viewClinicTreatmentHistory" ||
          task === "viewClinicTreatmentDetail" ||
          task === "viewDoctorTreatmentDetail" ||
          task === "viewDoctorTreatmentHistory" ||
          task === "viewNurseTreatmentDetail" ||
          task === "viewNurseTreatmentHistory" ||
          task === "viewPatientMedicalDetail" ||
          task === "viewPatientTreatmentHistory" ||
          task === "viewPatientTreatmentDetail"
        ) {
          if (store.state.AppActiveUser.isMedicalPerson && permissions[task])
            return true;
          else return false;
        } else {
          return permissions[task];
        }
      }

      return true;
    }
  }
});

new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount("#app");
